<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Stock</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Item With State Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Stock Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-10 d-flex mr-0 pr-0">
          <select v-model="filter.size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select class="ml-2">
            <option value="">2075/76</option>
            <option value="">2076/77</option>
          </select>
          <select v-model="filter.branch" class="filter-input ml-2">
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in dataLists2"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <ul class="ml-2">
            <li>
              <a href="javascript:;" @click="prevDay">
                <i class="fas fa-arrow-left"></i>
              </a>
            </li>
            <li><a href="javascript:;" @click="toDay"> Today </a></li>
            <li>
              <a href="javascript:;" @click="nextDay">
                <i class="fas fa-arrow-right"></i>
              </a>
            </li>
          </ul>
          <ul class="ml-2">
            <li>
              <a href="javascript:;" @click="prevMonth">
                <i class="fas fa-arrow-left"></i>
              </a>
            </li>
            <li><a href="javascript:;" @click="thisMonth"> Month </a></li>
            <li>
              <a href="javascript:;" @click="nextMonth">
                <i class="fas fa-arrow-right"></i>
              </a>
            </li>
          </ul>
          <div class="ml-2">
            <label class="filter-lavel mb-0 mr-1">From</label>
            <input
              type="date"
              v-model="filter.from"
              placeholder="From"
              class="filter-input"
            />
            <label class="filter-lavel mb-0 ml-1">To</label>
            <input
              type="date"
              v-model="filter.to"
              placeholder="To"
              class="ml-1 filter-input"
            />
          </div>
          <div class="ml-2">
            <input
              type="text"
              v-model="filter.key"
              class="filter-input"
              placeholder="Search..."
            />
          </div>
        </div>
        <div class="col-md-2 ml-0 pl-0">
          <select
            class="filter-input ml-2 float-right"
            v-model="route"
            @change="filterReportType"
          >
            <option value="/general-report/stock">
              Item With Quantity Wise
            </option>
            <option value="/general-report/stock/item-value">
              Item With Value Wise
            </option>
            <option value="/general-report/stock/item-state">
              Item With State Wise
            </option>
            <option value="/general-report/stock/day-quantity">
              Day With Quantity Wise
            </option>
            <option value="/general-report/stock/day-value">
              Day With Value Wise
            </option>
            <option value="/general-report/stock/month-quantity">
              Month With Quantity Wise
            </option>
            <option value="/general-report/stock/month-value">
              Month With Value Wise
            </option>
            <option value="/general-report/stock/branch-quantity">
              Branch With Quantity Wise
            </option>
            <option value="/general-report/stock/branch-value">
              Branch With Value Wise
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="bg-theam-secondary table-search-head">
      <div>
        <span class="font-s tx-bold mr-2 tx-primary"
          >In-House Stock: NPR 75,00,000,</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Transfering Stock: NPR 25,00,000</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Manufacturing Stock: NPR 25,00,000</span
        >
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N</th>
              <th class="wd-10p">Item Id</th>
              <th class="wd-15p">Item Name</th>
              <th class="wd-10p">Group</th>
              <th class="wd-10p">In-house Stock</th>
              <th class="wd-10p">In-house Value</th>
              <th class="wd-10p">Transf. Stock</th>
              <th class="wd-10p">Transf. Value</th>
              <th class="wd-10p">Manuf. Stock</th>
              <th class="wd-10p">Manuf. Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" class="table-start-item">1</th>
              <th>ITM001</th>
              <td>Dell Laptop</td>
              <td>Computer</td>
              <td>1000000 Pcs</td>
              <td>NPR 3000000</td>
              <td>4000000 Pcs</td>
              <td>NPR 2000000</td>
              <td>1000000 Pcs</td>
              <td>NPR 1500000</td>
            </tr>
          </tbody>
        </table>
        <Pagination />
      </div>
    </div>
  </div>
</template>
<script>
import { Search } from "../../../../../mixins/search";
import Pagination from "../../../components/pagination/pagination";

export default {
  mixins: [Search],
  components: {
    Pagination,
  },
  methods: {
    filterReportType() {
      this.$router.push(this.route);
    },
  },
};
</script>